import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

const StyledFooter = styled.div`
  background-color: #1c1c1c;
  color: ${(props) => props.theme.color.light};
`

const FooterMenu = styled.div`
  & ul {
    justify-content: flex-end;

    @media (max-width: 991px) {
      padding-left: 0;
    }

    & a:hover {
      text-decoration: underline;
    }
    list-style-type: none;
  }
`

const Content = styled(ParseContent)`
  & p {
    color: ${({ theme }) => theme.color.light};
    font-weight: ${({ theme }) => theme.font.weight.light};
  }
`

const FooterMenuLink = styled(Link)`
  font-size: ${({ theme }) => theme.font.size.small};
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.font.weight.light};
  color: ${({ theme }) => theme.color.light};
`

const Social = styled.a`
  font-size: 0;
`

const SocialIcon = styled(Plaatjie)`
  height: 19px;
  width: auto;
`

const Footer = () => {
  // eslint-disable-next-line
  const { fields } = useStaticQuery<GatsbyTypes.footerQueryQuery>(graphql`
    query footerQuery {
      fields: wpComponent(databaseId: { eq: 98 }) {
        footer {
          text {
            description
            phone {
              title
              url
            }
            email
          }
          menuFooter {
            link {
              title
              url
            }
          }
          socials {
            icon {
              localFile {
                publicURL
              }
            }
            link {
              title
              url
              target
            }
          }
        }
      }
    }
  `)

  return (
    <StyledFooter className="py-sm-5">
      <div className="container py-5">
        <div className="position-relative">
          <div className="row align-items-end">
            <div className="col-sm-6 col-lg-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="130"
                height="68"
                viewBox="0 0 130 68"
                fill="none"
              >
                <path
                  d="M16.2317 35.3448H5.20938C4.82166 35.3448 4.43394 35.2899 4.157 35.1253C3.88005 34.9606 3.6585 34.6862 3.49233 34.302L0.279785 13.1721C0.279785 12.7879 0.390563 12.4586 0.556729 12.2939C0.722895 12.1293 1.05523 12.0195 1.44295 12.0195H6.64949C7.03721 12.0195 7.36954 12.1293 7.59109 12.2939C7.81265 12.4586 7.92343 12.7879 7.92343 13.1721L9.75126 27.9905C9.80665 28.21 9.91742 28.3747 9.97281 28.4295C10.0836 28.4844 10.1944 28.4844 10.3605 28.4844H10.7483C10.9144 28.4844 11.0806 28.4844 11.136 28.4295C11.2468 28.3747 11.3021 28.21 11.3575 27.9905L13.1854 13.1721C13.1854 12.7879 13.2961 12.4586 13.4623 12.2939C13.6285 12.1293 13.9608 12.0195 14.3485 12.0195H19.4997C19.8874 12.0195 20.2197 12.1293 20.3859 12.2939C20.5521 12.4586 20.6628 12.7879 20.6628 13.1721L17.838 34.302C17.6718 34.6862 17.4503 35.0155 17.1733 35.1253C17.0072 35.235 16.6195 35.3448 16.2317 35.3448Z"
                  fill="white"
                />
                <path
                  d="M35.8946 35.3448H30.5773C30.1895 35.3448 29.8572 35.235 29.691 35.0704C29.5249 34.9057 29.4141 34.5764 29.4141 34.1923V18.2762H24.5953C24.2076 18.2762 23.8752 18.1664 23.7091 18.0018C23.5429 17.8371 23.4321 17.5078 23.4321 17.1236V13.1721C23.4321 12.7879 23.5429 12.4586 23.7091 12.2939C23.8752 12.1293 24.2076 12.0195 24.5953 12.0195H41.8766C42.2643 12.0195 42.5966 12.1293 42.7628 12.2939C42.9289 12.4586 43.0397 12.7879 43.0397 13.1721V17.1236C43.0397 17.5078 42.9289 17.8371 42.7628 18.0018C42.5966 18.1664 42.2643 18.2762 41.8766 18.2762H37.1131V34.1923C37.1131 34.5764 37.0024 34.9057 36.8362 35.0704C36.6146 35.235 36.3377 35.3448 35.8946 35.3448Z"
                  fill="white"
                />
                <path
                  d="M13.0742 66.9025H7.7569C7.31379 66.9025 7.03685 66.7927 6.87068 66.6281C6.70451 66.4634 6.59374 66.1341 6.59374 65.75V49.6693H1.66414C1.22103 49.6693 0.944086 49.5595 0.77792 49.3948C0.611754 49.2302 0.500977 48.9009 0.500977 48.5167V44.5651C0.500977 44.1261 0.611754 43.8517 0.77792 43.687C0.944086 43.5224 1.27642 43.4126 1.66414 43.4126H19.0562C19.4993 43.4126 19.7762 43.5224 19.9424 43.687C20.1086 43.8517 20.2194 44.181 20.2194 44.5651V48.5167C20.2194 48.9558 20.1086 49.2302 19.9424 49.3948C19.7762 49.5595 19.4439 49.6693 19.0562 49.6693H14.2374V65.6951C14.2374 66.1341 14.1266 66.4086 13.9604 66.5732C13.7943 66.7927 13.4619 66.9025 13.0742 66.9025Z"
                  fill="#89A561"
                />
                <path
                  d="M43.6492 60.7007C43.6492 61.6886 43.483 62.6216 43.2061 63.4449C42.9291 64.2681 42.3752 64.9816 41.5998 65.5304C40.8244 66.1342 39.8274 66.5732 38.498 66.9025C37.1687 67.2318 35.507 67.3965 33.4576 67.3965C31.4083 67.3965 29.7466 67.2318 28.4173 66.9025C27.0879 66.5732 26.0355 66.1342 25.3155 65.5304C24.5401 64.9267 24.0416 64.2681 23.7092 63.4449C23.4323 62.6216 23.2661 61.7435 23.2661 60.7007V44.5651C23.2661 44.1261 23.3769 43.8517 23.5431 43.687C23.7092 43.5224 24.0416 43.4126 24.4293 43.4126H29.5804C30.0235 43.4126 30.3005 43.5224 30.4667 43.687C30.6328 43.8517 30.7436 44.181 30.7436 44.5651V59.4384C30.7436 59.8775 30.9098 60.2617 31.2421 60.5361C31.5744 60.8105 32.2945 60.9752 33.4576 60.9752C34.6208 60.9752 35.3409 60.8105 35.6732 60.5361C36.0055 60.2617 36.1717 59.8775 36.1717 59.4384V44.5651C36.1717 44.1261 36.2825 43.8517 36.4486 43.687C36.6148 43.5224 36.9471 43.4126 37.3349 43.4126H42.3198C42.763 43.4126 43.0399 43.5224 43.2061 43.687C43.3722 43.8517 43.483 44.181 43.483 44.5651V60.7007H43.6492Z"
                  fill="#89A561"
                />
                <path
                  d="M47.7476 66.9023C47.3045 66.9023 47.0276 66.7925 46.8614 66.6279C46.6953 66.4632 46.5845 66.1339 46.5845 65.7498V61.8531C46.5845 61.414 46.6953 61.1396 46.8614 60.9749C47.0276 60.8103 47.3599 60.7005 47.7476 60.7005H49.6862V49.7239H47.7476C47.3045 49.7239 47.0276 49.6142 46.8614 49.4495C46.6953 49.2849 46.5845 48.9556 46.5845 48.5714V44.6198C46.5845 44.1808 46.6953 43.9063 46.8614 43.7417C47.0276 43.5771 47.3599 43.4673 47.7476 43.4673H59.2131C59.6562 43.4673 59.9331 43.5771 60.0993 43.7417C60.2655 43.9063 60.3763 44.2356 60.3763 44.6198V48.5714C60.3763 49.0105 60.2655 49.2849 60.0993 49.4495C59.9331 49.6142 59.6008 49.7239 59.2131 49.7239H57.2191V60.7005H59.2131C59.6562 60.7005 59.9331 60.8103 60.0993 60.9749C60.2655 61.1396 60.3763 61.4689 60.3763 61.8531V65.7498C60.3763 66.1888 60.2655 66.4632 60.0993 66.6279C59.9331 66.7925 59.6008 66.9023 59.2131 66.9023H47.7476Z"
                  fill="#89A561"
                />
                <path
                  d="M70.1802 66.9025H65.2506C64.8075 66.9025 64.5305 66.7927 64.3643 66.6281C64.1982 66.4634 64.0874 66.1341 64.0874 65.75V44.5651C64.0874 44.1261 64.1982 43.8517 64.3643 43.687C64.5305 43.5224 64.8628 43.4126 65.2506 43.4126H68.6847C69.1278 43.4126 69.4601 43.4675 69.7371 43.6321C70.014 43.7419 70.3463 44.0163 70.6233 44.2907L76.993 53.072V44.5651C76.993 44.1261 77.1038 43.8517 77.2699 43.687C77.4361 43.5224 77.7684 43.4126 78.1562 43.4126H83.0857C83.5289 43.4126 83.8058 43.5224 83.972 43.687C84.1381 43.8517 84.2489 44.181 84.2489 44.5651V65.6951C84.2489 66.1341 84.1381 66.4086 83.972 66.5732C83.8058 66.7379 83.4735 66.8476 83.0857 66.8476H78.1562H77.5469C77.2145 66.8476 76.9376 66.683 76.7714 66.4086L71.3987 58.0663V65.6951C71.3987 66.1341 71.2879 66.4086 71.1218 66.5732C70.9002 66.7927 70.6233 66.9025 70.1802 66.9025Z"
                  fill="#89A561"
                />
                <path
                  d="M115.876 66.9025H110.946C110.503 66.9025 110.226 66.7927 110.06 66.6281C109.894 66.4634 109.783 66.1341 109.783 65.75V44.5651C109.783 44.1261 109.894 43.8517 110.06 43.687C110.226 43.5224 110.559 43.4126 110.946 43.4126H114.38C114.824 43.4126 115.156 43.4675 115.433 43.6321C115.71 43.7419 116.042 44.0163 116.319 44.2907L122.689 53.072V44.5651C122.689 44.1261 122.8 43.8517 122.966 43.687C123.132 43.5224 123.464 43.4126 123.852 43.4126H128.782C129.225 43.4126 129.502 43.5224 129.668 43.687C129.834 43.8517 129.945 44.181 129.945 44.5651V65.6951C129.945 66.1341 129.834 66.4086 129.668 66.5732C129.502 66.7379 129.169 66.8476 128.782 66.8476H123.852H123.243C122.855 66.8476 122.523 66.683 122.301 66.3537L116.984 58.0663V65.6951C116.984 66.1341 116.873 66.4086 116.707 66.5732C116.596 66.7927 116.319 66.9025 115.876 66.9025Z"
                  fill="#89A561"
                />
                <path
                  d="M104.632 66.9025H89.3443C88.9012 66.9025 88.6243 66.7927 88.4581 66.6281C88.2919 66.4634 88.1812 66.1341 88.1812 65.75V44.5651C88.1812 44.1261 88.2919 43.8517 88.4581 43.687C88.6243 43.5224 88.9566 43.4126 89.3443 43.4126H104.632C105.075 43.4126 105.352 43.5224 105.518 43.687C105.684 43.8517 105.795 44.181 105.795 44.5651V48.4069C105.795 48.846 105.684 49.1204 105.518 49.2851C105.352 49.4497 105.019 49.5595 104.632 49.5595H95.5479V52.0841H101.973C102.416 52.0841 102.693 52.1939 102.859 52.3585C103.025 52.5232 103.136 52.8525 103.136 53.2366V56.7492C103.136 57.1882 103.025 57.4626 102.859 57.6273C102.693 57.7919 102.361 57.9017 101.973 57.9017H95.5479V60.7007H104.632C105.075 60.7007 105.352 60.8105 105.518 60.9751C105.684 61.1398 105.795 61.4691 105.795 61.8533V65.6951C105.795 66.1341 105.684 66.4086 105.518 66.5732C105.352 66.7927 105.075 66.9025 104.632 66.9025Z"
                  fill="#89A561"
                />
              </svg>
              <Content
                content={fields?.footer?.text?.description || ''}
                className="py-4"
              />
              <div className="d-flex flex-column">
                <a
                  href={fields?.footer?.text?.phone?.url}
                  className="d-flex align-items-center text-primary mb-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="19"
                    viewBox="0 0 11 19"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_615_799)">
                      <path
                        d="M9.58065 0H1.41935C0.620968 0 0 0.604545 0 1.38182V17.6182C0 18.3091 0.620968 19 1.41935 19H9.58065C10.379 19 11 18.3955 11 17.6182V16.15V1.46818C11 0.604545 10.379 0 9.58065 0ZM3.45968 0.863636H7.54032C7.62903 0.863636 7.71774 1.03636 7.71774 1.20909C7.71774 1.38182 7.62903 1.55455 7.54032 1.55455H3.45968C3.37097 1.55455 3.28226 1.38182 3.28226 1.20909C3.28226 0.95 3.37097 0.863636 3.45968 0.863636ZM5.5 17.6182C4.96774 17.6182 4.6129 17.1864 4.6129 16.7545C4.6129 16.2364 5.05645 15.8909 5.5 15.8909C6.03226 15.8909 6.3871 16.3227 6.3871 16.7545C6.3871 17.1864 6.03226 17.6182 5.5 17.6182ZM9.93548 14.5955H1.06452V2.33182H9.93548V14.5955Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_615_799">
                        <rect width="11" height="19" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span className="ms-2 ps-1 text-primary">
                    {fields?.footer?.text?.phone?.title}
                  </span>
                </a>
                <a href={`mailto:${fields?.footer?.text?.email}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_615_803)">
                      <path
                        d="M14.0466 0H1.95758C0.877393 0 0 0.864718 0 1.9293V10.0748C0 11.1394 0.877393 12.0041 1.95758 12.0041H14.0466C15.1267 12.0041 16.0041 11.1394 16.0041 10.0748V1.93338C16.0041 0.868797 15.1267 0 14.0466 0ZM15.1102 10.0748C15.1102 10.654 14.6342 11.123 14.0466 11.123H1.95758C1.36989 11.123 0.893947 10.654 0.893947 10.0748V1.93338C0.893947 1.35418 1.36989 0.885112 1.95758 0.885112H14.0466C14.6342 0.885112 15.1102 1.35418 15.1102 1.93338V10.0748Z"
                        fill="white"
                      />
                      <path
                        d="M10.09 5.89782L14.0052 2.43895C14.1873 2.2758 14.2038 1.99844 14.0383 1.81489C13.8727 1.63542 13.5913 1.6191 13.4051 1.78226L8.00827 6.55452L6.95705 5.62862C6.95292 5.62454 6.94878 5.62046 6.94878 5.62046C6.92394 5.59599 6.90325 5.57967 6.87428 5.55928L2.59078 1.78226C2.40455 1.6191 2.12312 1.63542 1.95757 1.81897C1.79203 2.00252 1.80858 2.27988 1.99482 2.44303L5.9555 5.93046L2.01137 9.5688C1.83341 9.73603 1.821 10.0134 1.99068 10.1929C2.08173 10.2826 2.19761 10.3315 2.31763 10.3315C2.42524 10.3315 2.53698 10.2908 2.62389 10.2133L6.62596 6.52189L7.71029 7.47634C7.7972 7.54976 7.90067 7.58647 8.00827 7.58647C8.11587 7.58647 8.22348 7.54568 8.30625 7.47226L9.42369 6.48518L13.4051 10.2133C13.492 10.2948 13.6037 10.3356 13.7113 10.3356C13.8313 10.3356 13.9472 10.2908 14.0341 10.1969C14.2038 10.0215 14.1955 9.74011 14.0176 9.57288L10.09 5.89782Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_615_803">
                        <rect width="16" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span className="ms-2 text-primary">
                    {fields?.footer?.text?.email}
                  </span>
                </a>
              </div>
              <div className="d-flex mt-4">
                {fields?.footer?.socials?.map(
                  (
                    { link: { url, title, target }, icon }: any,
                    index: number
                  ) => (
                    <Social
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      className="me-3"
                      href={url}
                      target={target}
                      rel="noopener noreferrer"
                    >
                      <SocialIcon image={icon} alt={title} />
                      {title}
                    </Social>
                  )
                )}
              </div>
            </div>
            <FooterMenu className="col-sm-6 col-lg-9 mt-5 mt-sm-0">
              <ul className="d-lg-flex mb-0">
                {fields?.footer?.menuFooter?.map(
                  ({ link: { url, title } }: any, index: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={index} className="ps-lg-4 ps-xl-5">
                      <FooterMenuLink to={url}>{title}</FooterMenuLink>
                    </li>
                  )
                )}
              </ul>
            </FooterMenu>
          </div>
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer
