import React from 'react'
import styled from 'styled-components'

const StyledTitlePrimary = styled.div`
  font-family: ${({ theme }) => theme.font.family.secondary};
  margin-bottom: 1rem;

  @media (min-width: 992px) {
    &:is(h2),
    &:is(h1) {
      font-size: ${({ theme }) => theme.font.size.giga};
      line-height: ${({ theme }) => theme.font.size.ultra};
    }

    &:is(h3) {
      font-size: ${({ theme }) => theme.font.size.mega};
      line-height: ${({ theme }) => theme.font.size.giga};
    }

    &:is(h4) {
      font-size: ${({ theme }) => theme.font.size.huge};
      line-height: ${({ theme }) => theme.font.size.mega};
    }
  }

  @media (max-width: 991px) {
    &:is(h2),
    &:is(h1) {
      font-size: ${({ theme }) => theme.font.size.mega};
      line-height: ${({ theme }) => theme.font.size.mega};
    }

    &:is(h3) {
      font-size: ${({ theme }) => theme.font.size.huge};
      line-height: ${({ theme }) => theme.font.size.huge};
    }

    &:is(h4) {
      font-size: ${({ theme }) => theme.font.size.large};
      line-height: ${({ theme }) => theme.font.size.large};
    }
  }

  @media (max-width: 575px) {
    &:is(h2),
    &:is(h1) {
      font-size: ${({ theme }) => theme.font.size.huge};
      line-height: ${({ theme }) => theme.font.size.huge};
    }

    &:is(h3) {
      font-size: ${({ theme }) => theme.font.size.large};
      line-height: ${({ theme }) => theme.font.size.large};
    }

    &:is(h4) {
      font-size: ${({ theme }) => theme.font.size.big};
      line-height: ${({ theme }) => theme.font.size.big};
    }
  }
`

interface TitlePrimaryProps {
  element: 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
  style?: any
}

const TitlePrimary: React.FC<TitlePrimaryProps> = ({
  element,
  children,
  style = {},
  ...rest
}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <StyledTitlePrimary as={element} style={style} {...rest}>
    {children}
  </StyledTitlePrimary>
)

export default TitlePrimary
