import React from 'react'
import styled, { css } from 'styled-components'
import ButtonShell from 'components/elements/Buttons/ButtonShell'

export const ButtonSecondaryCSS = css`
  background-color: transparent;
  display: inline-block;
  border: 1px solid ${({ theme }) => theme.color.light};

  @media (max-width: 991px){
    width: 100%;
  }
  
  &:hover {
    background-color: ${({ theme }) => theme.color.light};

    & > a,
    & > button {
      color: ${({ theme }) => theme.color.dark} !important;
      text-decoration: none !important;
    }
  }

  & > a,
  & > button {
    color: ${(props) => props.theme.color.light} !important;
    font-weight: ${({ theme }) => theme.font.weight.light};
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.medium};
      height: 50px;
      padding: 0 30px;
    }

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.small};
      height: 40px;
      padding: 0 20px;
    }
  }

  @media (min-width: 992px) {
    min-width: 220px;
  }
`

const StyledButton = styled.span`
  ${ButtonSecondaryCSS};
`

interface ButtonSecondaryProps {
  isAnchor?: boolean
  isCustom?: boolean
  to: string
  className?: string
  target?: string
}

const ButtonSecondary: React.FC<ButtonSecondaryProps> = ({
  isAnchor = false,
  isCustom = false,
  to,
  children,
  className = '',
  target = '',
}) => (
  <StyledButton className={className}>
    {isCustom ? (
      children
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor} target={target}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonSecondary
