import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { Helmet } from 'react-helmet'

// Icon
import favicon from 'img/favicon.png'

const removeHTML = (content: string) => {
  let newContent = content

  if (typeof document !== 'undefined') {
    const titleHtml = newContent
    const div = document.createElement('div')
    div.innerHTML = titleHtml
    newContent = div.innerText
  }

  return newContent
}

interface SEOProps {
  seo: any
}

const SEO: React.FC<SEOProps> = ({ seo }) => {
  const {
    site,
    wpComponent,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.socialSharingQueryQuery>(graphql`
    query socialSharingQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
      wpComponent(databaseId: { eq: 204 }) {
        socialSharing {
          image {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `)

  const defaultImageUrl = wpComponent?.socialSharing?.image
    ? {
        url: `${site?.siteMetadata?.siteUrl}${wpComponent?.socialSharing?.image?.localFile?.publicURL}`,
      }
    : ''

  const image: any = seo.opengraphImage
    ? {
        url: seo.opengraphImage.sourceUrl,
        width: 800,
        height: 600,
        alt: seo.opengraphTitle,
      }
    : defaultImageUrl

  return (
    <>
      <GatsbySeo
        language="nl"
        title={removeHTML(seo.title) || ''}
        description={removeHTML(seo.metaDesc) || ''}
        canonical={seo.canonical || ''}
        openGraph={{
          type: seo.opengraphTitle || '',
          url: seo.opengraphUrl || '',
          title: removeHTML(seo.title) || '',
          description: removeHTML(seo.metaDesc) || '',
          images: [image],
        }}
      />
      <Helmet>
        <link rel="icon" href={favicon} />

        <script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid="6aa9605b-cd70-4db4-9fbf-fed552facc64"
          type="text/javascript"
          async
        />

        <script>
          {`
        window.markerConfig = {
          project: '671a0693f15eb31e0e64d37d', 
          source: 'snippet'
        };

        !function(e,r,a){if(!e.__Marker){e.__Marker={};var t=[],n={__cs:t};["show","hide","isVisible","capture","cancelCapture","unload","reload","isExtensionInstalled","setReporter","clearReporter","setCustomData","on","off"].forEach(function(e){n[e]=function(){var r=Array.prototype.slice.call(arguments);r.unshift(e),t.push(r)}}),e.Marker=n;var s=r.createElement("script");s.async=1,s.src="https://edge.marker.io/latest/shim.js";var i=r.getElementsByTagName("script")[0];i.parentNode.insertBefore(s,i)}}(window,document);
        `}
        </script>
      </Helmet>
    </>
  )
}

export default SEO
